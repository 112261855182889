@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Onest:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');




html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Open Sans";
}
.loader {
  width: 100%;
  height: 100%;
 /* background-image: url("/images/emperior0.jpg");*/
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
}
.girl-1-left {
  position: fixed;
  z-index: 6;
  bottom: 0;
  right: 5px;
  display: none;
}
.girl-1-left img {
  display: block;
}
.girl-1 {
  transition: all ease 0.6s;
}
.text {
  width: 90%;
  border-radius: 10px;
  font-size: 24px;
  display: none;
  background: #fff;
  position: fixed;
  z-index: 7;
  overflow: hidden; /* Не показывать текст за пределами блока */
  border-right: 3px solid; /* Имитация курсора */
  left: 5%;
  top: 15px;
}
.more-1, .more-2, .more-3 {
  display: none;
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-weight: bold;
  color: #ff0000;
  font-size: 15px;
}
.list-pages {
  width: 90%;
  background: rgba(0,0,0,0.89);
  margin: auto;
  height: 250px;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 20px;
  right: 0;
  bottom: 0;
}
.list-pages-wrapper {
  padding: 15px;
}
.list-pages a {
  color: #0ab300;
  font-weight: bold;
  margin-bottom: 9px;
  text-decoration: none;
  display: block;
}
.fade-out {
  opacity: 0.6;
}
.fade-in {
  opacity: 1;
}
.text-1 {
  color: #000;
  padding: 15px;
  font-size: 15px;

}
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}
@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.align-between {
  justify-content: space-between;
}
.align-left {
  justify-content: left;
}
.align-right {
  justify-content: right;
}
.align-center {
  justify-content: center;
}
.vertical-center {
  align-items: center;
}
.content {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.base {
  background-image: url("images/base.jpg");
  background-position: 26% bottom;
}
.courtyard {
  /*background-image: url("/images/field.jpg");*/
  background-position: 26% bottom;
}
.base-start-inside {
  /*background-image: url("/images/base-start.png");*/
  background-position: 26% bottom;
}
.citadel {
  position: fixed;
  left: calc(50% - 50px);
  top: 50px;
  z-index: 22;
}

.logo {

  width: 100%;
  height: 100%;
  margin: 15px 0 0 0;
  z-index: 5;
  display: none;
  position: fixed;
  top: 15px;
}
.logo img {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.button-start {
  background: #2ca100;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 65%;
  padding: 18px 0;
  box-shadow: 0 0 10px #000;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 15vh;
  font-size: 20px;
  z-index: 5;
  display: none;
}
.text-center {
  text-align: center;
}
.fs-13 {
  font-size: 13px;
}
.text-white {
  color: #fff;
}
.right-15 {
  right: 15px;
}
.position-fixed {
  position: fixed;
}
.z-index-21 {
  z-index: 21;
}
.bold {
  font-weight: bold;
}
.bottom-15 {
  bottom: 15px;
}
.w-100 {
  width: 100%;
}
.bottom-100 {
  bottom: 100px;
}
.bottom-82 {
  bottom: 82px;
}
.bottom-25 {
  bottom: 26px;
}
.top-15 {
  top: 15px;

}
.left-15 {
  left: 15px;
}
.builds {
  position: fixed;
  z-index: 22;
  left: 0;
  top: 200px;
  width: 100%;
  overflow-x: scroll; /* Включаем горизонтальную прокрутку */
  touch-action: pan-x; /* Позволяет использовать свайпы для прокрутки */
  display: flex;
  justify-content: flex-start; /* Начинаем с левого края, чтобы прокрутка работала корректно */
  padding-left: 20px; /* Добавляем отступ слева для лучшего визуального эффекта */
}

.builds-wrapper {
  width: 800px; /* Ширина больше, чем контейнер */
  display: flex;
  flex-wrap: nowrap; /* Не переносим элементы на новые строки */
  gap: 40px; /* Увеличиваем расстояние между элементами */
  padding: 0 50px; /* Немного смещаем содержимое для эффекта "прокрутки" */
  margin-left: calc((100vw - 306px) / 2); /* Центрируем элементы относительно экрана */
}

.build-item {
  width: 102px;
  height: 102px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.build-level {
  position: absolute;
  left: 0;
  width: 15px;
  font-size: 9px;
  color: #424242;
  padding: 1px 0;
  text-align: center;
  background: #fff;
  border: solid 1px #424242;
  font-weight: 800;
  opacity: 0.85;
  border-radius: 4px;
}

.build-item-wrapper {
  padding: 0 8px;
}
.build-img {
  display: block;
  width: 100%;
}
.build-icon {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 50%;
  width: 25px;
  height: 25px;
  background: #0a53be;
  z-index: 50;
}
.overlay {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  background: #333;
  position: fixed;
}
.cssload-thecube {
  width: 73px;
  height: 73px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  z-index: 101;
}
.cssload-thecube .cssload-cube {
  position: relative;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(29,160,232);
  animation: cssload-fold-thecube 2.76s infinite linear both;
  -o-animation: cssload-fold-thecube 2.76s infinite linear both;
  -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
  -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
  -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg);
  -o-transform: scale(1.1) rotateZ(90deg);
  -ms-transform: scale(1.1) rotateZ(90deg);
  -webkit-transform: scale(1.1) rotateZ(90deg);
  -moz-transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg);
  -o-transform: scale(1.1) rotateZ(180deg);
  -ms-transform: scale(1.1) rotateZ(180deg);
  -webkit-transform: scale(1.1) rotateZ(180deg);
  -moz-transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg);
  -o-transform: scale(1.1) rotateZ(270deg);
  -ms-transform: scale(1.1) rotateZ(270deg);
  -webkit-transform: scale(1.1) rotateZ(270deg);
  -moz-transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
  animation-delay: 0.35s;
  -o-animation-delay: 0.35s;
  -ms-animation-delay: 0.35s;
  -webkit-animation-delay: 0.35s;
  -moz-animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
  animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -moz-animation-delay: 1.04s;
}



@keyframes cssload-fold-thecube {
  0%, 10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-o-keyframes cssload-fold-thecube {
  0%, 10% {
    -o-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -o-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -o-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-ms-keyframes cssload-fold-thecube {
  0%, 10% {
    -ms-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -ms-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -ms-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-fold-thecube {
  0%, 10% {
    -webkit-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}

@-moz-keyframes cssload-fold-thecube {
  0%, 10% {
    -moz-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -moz-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -moz-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}